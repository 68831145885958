html[data-bs-theme="dark"] .text-success {
    color: #19b219!important;
}

html[data-bs-theme="dark"] .text-info {
    color: #6edff6!important;
}

html[data-bs-theme="dark"] .text-danger {
    color: #ff0000!important;
}

html[data-bs-theme="light"] .text-info {
    color: #008182!important;
}

.bg-purple {
    background: #008182!important;
}

.m-auto {
    margin-left: auto;
    margin-right: auto;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default;
}


.text-center {
    text-align: center;
}

.overflow-line-dots {
    text-overflow: ellipsis;
    overflow: hidden;
    height: 1.2em;
    white-space: nowrap;
}

.radio-checkbox-list {
    height: 25px!important;
    width: 45px!important;
}